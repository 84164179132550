import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {createApi, getEpigrafeApi, updateEpigrafeApi} from "../../../../services/Admin/epigrafe";
import InputText from "../../../../components/Inputs/InputText";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Button, Grid, Paper} from "@mui/material";
import {ArrowBackIos} from "@mui/icons-material";

export default function Edit() {
    const {id} = useParams()
    const {logout} = useAuth()
    const history = useHistory()
    const {login} = useAuth()

    const [msgError, setMsgError] = useState([])
    const [state, setState] = useState(initialValues)


    // Llamamos a la API para obtener los datos del epigrafe
    useEffect(() => {
        if (id) {
            void getEpigrafe()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getEpigrafe = async () => {
        const response = await getEpigrafeApi(logout, id)
        if (response.status) {
            setState(initialValues(response.data))
        }
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        if (id) {
            void updateEpigrafe()
        } else {
            void createEpigrafe()
        }
    }

    const updateEpigrafe = async () => {
        setMsgError([])

        const response = await updateEpigrafeApi(logout, state)
        if (response.status) {
            history.push("/admin/epigrafes/management")
        } else {
            setMsgError(response.errors)
        }
    }

    const createEpigrafe = async () => {
        setMsgError([])
        const response = await createApi(state)
        if (response.status) {
            history.push("/admin/epigrafes/management")
        } else {
            setMsgError(response.errors)
        }
    }

    return (
        <>
            <HeaderPage title="Ajustes de Epigrafe"/>

            <MsgError msgError={msgError}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">Ajustes</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <div className="mb-2">
                                    <InputText
                                        label="Nombre"
                                        name="name"
                                        value={state?.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="pt-20">
                <Grid item xs={6} md={3} lg={3}>
                    <Button
                        type="button"
                        href="/admin/epigrafes/management"
                        fullWidth
                    >
                        <ArrowBackIos/>
                        Volver al listado
                    </Button>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        onClick={handleSubmit}
                    >
                        {id ? "Modificar" : "Crear"}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        id: props?.id || "",
        name: props?.name || "",
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.name) {
        error.push("El nombre es obligatorio")
    }

    return error
}
