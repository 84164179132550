import React, {useEffect, useMemo, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import {Button, Checkbox, Grid, IconButton, Paper, TextField} from "@mui/material";
import {
    deleteOrganizationApi,
    deleteOrganizationsApi,
    showOrganizationsApi
} from "../../../../services/Admin/organization";
import MsgError from "../../../../components/MsgError";
import {Delete, Edit} from "@mui/icons-material";
import PopupDelete from "../../../../components/PopupDelete";
import MsgSuccess from "../../../../components/MsgSuccess";
import InputText from "../../../../components/Inputs/InputText";
import {Link} from "react-router-dom";
import Table from "../../../../components/Table";
import {DASH_MAX_ROW} from "../../../../utils/constants";

export default function Management() {
    const [organizations, setOrganizations] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [state, setState] = useState(initialState)
    const {logout} = useAuth()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState("")
    const [selection, setSelection] = useState([])
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
        message: null
    });
    const [loading, setLoading] = useState(false)

    const search = window.location.search;
    const param = new URLSearchParams(search);
    const archived = param.get('archived');

    const handleChangeSelection = (e) => {
        let value = parseInt(e.target.value)
        if (selection.includes(value)) {
            setSelection(selection.filter(item => item !== value))
        } else {
            setSelection([...selection, value])
        }

    }

    useEffect(() => {
        setOrganizations([])
        const timeOutId = setTimeout(() => {
                getOrganizationsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getOrganizationsData = () => {
        setLoading(true)
        setMsgError([])
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            clientCode: "",
            nif: "",
            name: "",
            chineseName: "",
            archived: archived
        }

        if (state.nif) {
            params.nif = state.nif
        }
        if (state.clientCode) {
            params.clientCode = state.clientCode
        }
        if (state.name) {
            params.name = state.name
        }
        if (state.chineseName) {
            params.chineseName = state.chineseName
        }
        showOrganizationsApi(logout, params)
            .then(data => {
                let position = {}

                data.data.forEach((doc, key) => {
                    position[`${key}`] = false
                })

                setOrganizationsData(data.data)
                setQuantity(data.count)
                setLoading(false)
            })
            .catch(error => {
                setMsgError([error.response.data.errors])
                setLoading(false)
            });
    }

    const setOrganizationsData = (snapshot) => {
        const temp = snapshot.map((doc) => {
            const href = `/admin/organizations/edit/${doc.id}`
            return {
                epigrafeName: doc?.epigrafe?.name,
                eliminado: doc?.deleted ? "Si" : "No",
                actions: (
                    <Grid container>
                        <Grid item xs={6}>
                            <Button variant="text" color="primary" href={href} target={"_blank"}><Edit/></Button>
                        </Grid>
                        {!doc.deleted && (
                            <Grid item xs={6}>
                                <Button variant="text" onClick={() => {
                                    handleDelete(doc.id)
                                }}><Delete/></Button>
                            </Grid>
                        )}

                    </Grid>
                ),
                ...doc,
            }
        })
        setOrganizations(temp)
        setLoading(false)

    }

    const handleDelete = (id) => {
        setPopup({
            show: true,
            id
        });
    };

    const handleDeleteTrue = () => {
        setMsgError([]);
        setMsgSuccess("")
        if (popup.show && popup.id) {

            const idx = popup.id

            const request = (typeof idx === "object") ? deleteOrganizationsApi(logout, idx) : deleteOrganizationApi(logout, idx)

            request.then(response => {
                setMsgSuccess(response.data)
                getOrganizationsData()
            }).catch(error => {
                setMsgError([error.response.data.errors])
            })
            setPopup({
                show: false,
                id: null,
                message: null
            });
        }
    };

    const handleDeleteFalse = () => {
        setPopup({
            show: false,
            id: null,
            message: null
        });
    };

    const handleBulkDelete = () => {

        setPopup({
            show: true,
            id: selection,
            message: "¿Estás seguro de querer eliminar estos registros?"
        })

    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const headerText = archived === "1" ? "Organizaciones Archivadas" : "Organizaciones Activas";
    return (
        <>
            {popup.show && (
                <PopupDelete
                    show={popup.show}
                    handleDeleteTrue={handleDeleteTrue}
                    handleDeleteFalse={handleDeleteFalse}
                    message={popup.message}
                />
            )}
            <Grid item xs={6}>
                <h1>{headerText}</h1>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={3} justifyContent="flex-end">
                    {(selection.length > 0 || state.checkAll) && (
                        <>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleBulkDelete}
                                    style={{float: "right", margin: "25px 0px"}}>
                                    Eliminar
                                </Button>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{float: "right", margin: "25px 0px"}}
                            href="/admin/organizations/create">
                            + Añadir
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, m: 3, display: 'flex', flexDirection: 'column'}}>
                        <MsgSuccess msgSuccess={msgSuccess}/>
                        <MsgError msgError={msgError}/>
                        <Filters
                            state={state}
                            setState={setState}
                            setSelection={setSelection}
                            organizations={organizations}
                        />
                        <Table
                            columns={tableColumns(handleChangeSelection, handleDelete, selection, state)}
                            rows={organizations}
                            rowCount={quantity}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function initialState(props) {
    return {
        name: '',
        clientCode: '',
        nif: '',
        chineseName: '',
        checkAll: false,
        offset: 0,
        sort: {field: "id", sort: "desc"},
    }
}

function tableColumns(handleChangeSelection, handleDelete, selection, state) {
    return [
        {
            field: "checkbox",
            headerName: "",
            sortable: false,
            renderCell: (params) => {
                let checked = !state.checkAll ? selection.includes(parseInt(params.id)) : true
                return (
                    <>
                        <Checkbox onChange={handleChangeSelection} value={params.id} checked={checked}/>
                    </>
                )
            },
        },
        {
            headerName: "COD. CLIENTE",
            field: "clientCode",
            width: 100,
            sortable: true
        },
        {
            headerName: 'CODIGO INTERNO',
            field: 'codInterno',
            width: 100,
            sortable: true
        },
        {
            headerName: 'NIF',
            field: 'nif',
            sortable: true,
            width: 100
        },
        {
            headerName: "NIF/IVA",
            field: "nifiva",
            sortable: true,
            width: 400
        },
        {
            headerName: "EPIGRAFE",
            field: "epigrafeName",
            sortable: true,
            width: 400
        },
        {
            headerName: "NOMBRE",
            field: "name",
            sortable: true,
            width: 100,
        },
        {
            headerName: "NOMBRE CHINO",
            field: "nameChinese",
            sortable: true,
            width: 100,
        },
        {
            headerName: "ACCIONES",
            field: "actions",
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/admin/organizations/edit/${params.id}`} target="_blank" rel="noopener noreferrer">
                            <IconButton size="small">
                                <Edit fontSize="inherit" />
                            </IconButton>
                        </Link>
                        {!params.deleted && (
                            <IconButton
                                variant="text"
                                onClick={() => {handleDelete(params.id)}}
                                size="small"
                            >
                                <Delete fontSize="inherit"/>
                            </IconButton>
                        )}
                    </>
                )
            },

        }

    ]
}
function Filters(props) {

    const {state, setState, setSelection, organizations} = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const handleChangeCheckAll = (event) => {
        let tempSelection = []
        state[event.target.name] = event.target.checked
        if (!event.target.checked) {
            setSelection([])
        }else{
            organizations.forEach(item => {
                item.checked = true
                tempSelection.push(item.id)
            })
            setSelection(tempSelection)
        }
        setState({...state})
    }
    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>

            <Grid item xs={3}>
                <InputText
                    label="Código cliente"
                    name="clientCode"
                    value={state.clientCode}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={3}>
                <InputText
                    label="NIF"
                    name="nif"
                    onChange={handleChange}
                    value={state.nif}
                />
            </Grid>

            <Grid item xs={3}>
                <InputText
                    label="Nombre"
                    name="name"
                    onChange={handleChange}
                    value={state.name}
                />
            </Grid>

            <Grid item xs={3}>
                <InputText
                    label="Nombre chino"
                    name="chineseName"
                    onChange={handleChange}
                    value={state.chineseName}
                />
            </Grid>

            <Grid item xs={3}>
                <Checkbox name="checkAll" onChange={handleChangeCheckAll} checked={state.checkAll}/>
                Marcar todos los registros
            </Grid>
        </Grid>
    )
}
