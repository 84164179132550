import React, {useEffect, useMemo, useRef, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import TableFilter, {SelectColumnFilter} from "../../../../components/Admin/TableFilter";
import {Button, Grid, Link, Paper, TextField, Box, Select} from "@mui/material";
import {importSalesApi} from "../../../../services/Admin/sale";
import {showImportSalesApi} from "../../../../services/Admin/sale";
import {loadImportSalesApi} from "../../../../services/Admin/sale";
import InputFile from "../../../../components/Inputs/InputFile";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";


export default function Import() {
    const [sales, setSales] = useState([])
    const {logout} = useAuth()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues())
    const form = useRef()
    const [file, setFile] = useState(null)

    const curYear = new Date().getFullYear()
    let optionsSaleYear = []
    for (let i = 2022; i <= curYear; i++) {
        optionsSaleYear.push({ [i]: i })
    }
    const optionsSaleQuarter = [{"1T": "Primer trimestre"}, {"2T": "Segundo trimestre"}, {"3T": "Tercer trimestre"}, {"4T": "Cuarto trimestre"}]

    const columns = useMemo(
        () => [
            {
                Header: "COD. CLIENTE",
                accessor: "organizationClientCode",
                width: "200px"
            },
            {
                Header: "NIF",
                accessor: "organizationNif",
                width: "200px"
            },
            {
                Header: "ORGANIZACION",
                accessor: "organizationName",
                width: "200px"
            },
            {
                Header: "ORGANIZACION CHINO",
                accessor: "organizationNameChinese",
                width: "200px"
            },
            {
                Header: "VENTA EXENTA DE IVA",
                accessor: "exempt",
                width: "200px"
            },
            {
                Header: "VENTA CON IVA",
                accessor: "vat",
                width: "200px"
            },
            {
                Header: "DEVOLUCIÓN OP INTERIOR",
                accessor: "interiorOpRefund",
                width: "200px"
            },
            {
                Header: "ADQUISICION INTERIOR",
                accessor: "interiorAcquisition",
                width: "200px"
            },
            {
                Header: "IVA PAGADO IMPORTACION",
                accessor: "importVat",
                width: "200px"
            },
            {
                Header: "VENTA B2B INTERIOR",
                accessor: "interiorB2b",
                width: "200px"
            },
            {
                Header: "ENTREGA INTRA.",
                accessor: "intracommunity",
                width: "200px"
            },
            {
                Header: "ADQUISICION INTRA.",
                accessor: "intracommunityAcquisition",
                width: "200px"
            },
            {
                Header: "EXPORTACIÓN",
                accessor: "export",
                width: "200px"
            },
            {
                Header: "IVA TRIMESTRE ANTERIOR",
                accessor: "previousQuarter",
                width: "200px"
            },
            {
                Header: "VENTA NO SUJETA",
                accessor: "notSubject",
                width: "200px"
            }
        ],
        []
    )
    // useEffect(() => {
    //     getSalesData();
    // }, [logout])

    function loadImportSales() {
        showImportSalesApi(logout)
            .then(data => {
                setSalesData(data.data)
            })
            .catch(() => {
            });
    }

    function setSalesData(snapshot) {
        const temp = snapshot.map((doc) => {
            return {
                organizationName: doc?.organization?.name,
                organizationNif: doc?.organization?.nif,
                organizationClientCode: doc?.organization?.clientCode,
                organizationNameChinese: doc?.organization?.nameChinese,
                ...doc
            }
        })
        setSales(temp)
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeFile = (event) => {
        setFile(event.target.files)
        state[event.target.name] = true
        setState({...state})
        
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setMsgError([])
        setSales(null)
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return
        }
        
        void importSales()


    }

    const loadData = async (event) => {
        const response = await loadImportSalesApi(logout)
        if (response.data.response === "success") {
            setMsgSuccess(response.data.msg)
            setSales(null)
        } else {
            setMsgError(response.errors)
        }
    }

    const importSales = async () => {
        setMsgError([])
        setMsgSuccess([])
        let f = new FormData()
        for (const property in state) {
            if (property !== "file"){
                f.append(`${property}`, state[property])
            }
            
        }
        if (file) {
            f.append("file", file[0])
        }
        const response = await importSalesApi(logout, f).then(response => {
            loadImportSales()
            setMsgSuccess(response.data.msg)
        }).catch(error => {
            form.current.reset()
            setMsgError(error.response.data.errors)
        })
    }
    const downloadTemplate = () => {
        window.open("/import_ventas.xlsx", '_blank');
    }

    return (
        <>
            <Grid item xs={6}>
                <h1>Importación Ventas</h1>
            </Grid>
            <Grid item xs={6} style={{textAlign: "right"}}>
                <div className="mt-2">
                    <Button
                        type="button"
                        onClick={downloadTemplate}
                        variant="contained"
                        color="primary">Descargar plantilla</Button>
                </div>
            </Grid>
            <Grid item xs={2}>
                <form ref={form}>
                    <InputFile
                        label="Importar"
                        name="file"
                        onChange={handleChangeFile}
                    />
                </form>
            </Grid>
            <Grid item xs={2}>
                <InputSelect
                    label="Año Ventas"
                    name="year"
                    required={true}
                    options={optionsSaleYear}
                    value={state?.year}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={2}>
                <InputSelect
                    label="Trimestre Ventas"
                    name="quarter"
                    required={true}
                    options={optionsSaleQuarter}
                    value={state?.quarter}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6}>
                <Button
                    type="button"
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    >
                    Importar XLS Ventas
                </Button>
            </Grid>
            <MsgSuccess msgSuccess={msgSuccess}/>
            <MsgError msgError={msgError}/>
            <Grid item xs={12}>
                <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                    <TableFilter
                        columns={columns}
                        data={sales ? sales : []}
                        search={false}
                        selectable={false}
                    />
                </Paper>
            </Grid>
            <Button
                variant="contained"
                color="primary"
                type="button"
                sx={{margin: 3}}
                onClick={loadData}
            >
                Validar Ventas
            </Button>
            
        </>
    );
}
function initialValues() {
    return {
        file: null,
        year: "",
        quarter: "",
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.file) {
        error.push("El fichero es obligatorio")
    }
    if (!schema.year) {
        error.push("El año de ventas es obligatorio")
    }
    if (!schema.quarter) {
        error.push("El trimestre es obligatorio")
    }


    return error
}
