import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import { Get, Post, Put } from "../../utils/request";
import {getToken} from "../Auth/token";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}`;
const token = `Bearer ${getToken()}`;

export async function createApi(formData) {
    const url = `${baseUrl}/user/create`;
    const ret = await Post(url, null, formData, token);
    return ret;
}

export async function showUsersApi(logout, params) {
    const url = `${baseUrl}/user/list`;
    const ret = await Get(url, logout, token, params);
    return ret;
}

export async function getUserApi(logout, id) {
    const url = `${baseUrl}/user/${id}`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function updateUserApi(logout, data) {
    const url = `${baseUrl}/user/update`;
    const ret = await Put(url, logout, data, token);
    return ret;
}
