import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {createApi, getOrganizationsApi, updateOrganizationsApi} from "../../../../services/Admin/organization";
import InputText from "../../../../components/Inputs/InputText";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Button, Grid, Paper, TextField} from "@mui/material";
import InputSelect from "../../../../components/Inputs/InputSelect";
import {DesktopDatePicker, LocalizationProvider} from "@mui/lab";
import {showEpigrafesApi} from "../../../../services/Admin/epigrafe";
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from "moment";
import InputNumber from "../../../../components/Inputs/InputNumber";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import DatePickerSimple from "../../../../components/Inputs/DatePickerSimple";
import LoadingProgress from "../../../../components/LoadingProgress";


export default function Edit() {
    const {id} = useParams()
    const {logout} = useAuth()
    const history = useHistory()
    const [msgError, setMsgError] = useState([])
    const [state, setState] = useState(initialValues)
    const optionsStatus = [{COBRADO: "Cobrado"}, {PENDIENTE: "Pendiente"}, {BAJA: "Baja"}]
    const optionsRate = [{ANUAL: "Anual"}, {TRIMESTRAL: "Trimestral"}, {Mensual: "Mensual"}]
    const optionsRegime = [{ORDINARIO: "Ordinario"}, {SIMPLIFICADO: "Simplificado"}]
    const optionspPerteneciente = [{USUARIO_A: "Usuario A"}, {USUARIO_B: "Usuario B"}, {USUARIO_C: "Usuario C"}]
    const [optionsEpigrafe, setOptionsEpigrafe] = useState([])
    const [loading, setLoading] = useState(false)

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        getEpigrafes()
        if (id) {
            void getOrganization()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getOrganization = async () => {
        setLoading(true)
        const response = await getOrganizationsApi(logout, id)
        if (response.status) {
            setState(initialValues(response.data))
        }
        setLoading(false)
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked ? 1 : 0
        setState({ ...state })
    }

    const getEpigrafes = () => {
        showEpigrafesApi(logout, {limit: 100, offset: 0}).then(res => {
            let temp = res.data.map(doc => {
                let obj = {}
                obj[doc.id] = doc.name
                return obj
            })
            setOptionsEpigrafe(temp)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        if (id) {
            await updateOrganization()
        } else {
            await createOrganization()
        }
    }

    const updateOrganization = async () => {
        setMsgError([])

        const response = await updateOrganizationsApi(logout, {
            ...state,
            startDate: state.startDate ? moment.unix(state.startDate).utc().format("MM/DD/YYYY") : null,
            endDate: state.endDate ? moment.unix(state.endDate).utc().format("MM/DD/YYYY") : null
        })

        if (response.status) {
            history.push("/admin/organizations/management")
        } else {
            setMsgError(response.errors)
        }
    }

    const createOrganization = async () => {
        setMsgError([])
        const response = await createApi(state)
        if (response.status) {
            history.push("/admin/organizations/management")
        } else {
            setMsgError(response.errors)
        }
    }

    return (
        <>
            <HeaderPage title="Configuración de la organización"/>

            <MsgError msgError={msgError}/>

            {loading ? (
                <LoadingProgress show={true}/>
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <h4 className="p-0 mt-0">Ajustes</h4>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <InputText
                                            label="Nombre"
                                            name="name"
                                            required
                                            value={state?.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <InputText
                                            label="Nombre chino"
                                            name="nameChinese"
                                            required
                                            value={state?.nameChinese}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputText
                                            label="NIF"
                                            name="nif"
                                            value={state?.nif}
                                            required
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputText
                                            label="NIF/IVA"
                                            name="nifiva"
                                            value={state?.nifiva}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputText
                                            label="IBAN"
                                            name="iban"
                                            value={state?.iban}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputText
                                            label="SWIFT"
                                            name="swift"
                                            value={state?.swift}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputText
                                            label="Código interno"
                                            name="internalCode"
                                            value={state?.internalCode}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputNumber
                                            label="Código cliente"
                                            name="clientCode"
                                            required
                                            value={state?.clientCode}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputSelect
                                            label="Estado"
                                            name="status"
                                            required
                                            onChange={handleChange}
                                            value={state?.status}
                                            options={optionsStatus}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputSelect
                                            label="Tarifa"
                                            name="rate"
                                            required
                                            onChange={handleChange}
                                            value={state?.rate}
                                            options={optionsRate}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                     <InputSelect
                                            label="Régimen"
                                            name="regimen"
                                            required
                                            onChange={handleChange}
                                            value={state?.regimen}
                                            options={optionsRegime}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputSelect
                                            label="Perteneciente"
                                            name="perteneciente"
                                            required
                                            onChange={handleChange}
                                            value={state?.perteneciente}
                                            options={optionspPerteneciente}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <InputSelect
                                            label="Epígrafe"
                                            name="epigrafe"
                                            onChange={handleChange}
                                            value={state?.epigrafe}
                                            options={optionsEpigrafe}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <DatePickerSimple
                                            label="Fecha de Alta"
                                            name="startDate"
                                            value={state?.startDate}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <DatePickerSimple
                                            label="Fecha de Baja"
                                            name="endDate"
                                            value={state?.endDate}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <InputSwitch
                                            label="Archivada"
                                            name="isArchived"
                                            checked={state?.isArchived}
                                            onChange={handleChangeChecked}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                        <Grid item xs={6} md={3} lg={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                                onClick={handleSubmit}
                            >
                                {id ? "Modificar" : "Crear"}
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}

function initialValues(props) {
    return {
        id: props?.id || "",
        name: props?.name || "",
        nameChinese: props?.nameChinese || "",
        nif: props?.nif || "",
        nifiva: props?.nifiva || "",
        status: props?.status || "",
        rate: props?.rate || "",
        internalCode: props?.internalCode || "",
        clientCode: props?.clientCode || "",
        epigrafe: props?.epigrafe?.id || "",
        startDate: (props?.startDate !== null) ? moment(props?.startDate).utc().format("X") : null,
        endDate: (props?.endDate) ? moment(props?.endDate).utc().format("X") : null,
        perteneciente: props?.perteneciente || "",
        regimen: props?.regimen || "",
        isArchived: props?.isArchived || false,
        iban: props?.iban || "",
        swift: props?.swift || "",
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.name) {
        error.push("El nombre es obligatorio")
    }
    if (!schema.nameChinese) {
        error.push("El nombre en chino es obligatorio")
    }
    if (!schema.nif) {
        error.push("El NIF es obligatorio")
    }
    if (!schema.clientCode) {
        error.push("El código de cliente es obligatorio")
    }
    if (!schema.status) {
        error.push("El estado es obligatorio")
    }
    if (!schema.rate) {
        error.push("La tarifa es obligatoria")
    }
    if (!schema.regimen) {
        error.push("El régimen es obligatorio")
    }
    if (!schema.epigrafe) {
        error.push("El epígrafe es obligatorio")
    }
    if (!schema.startDate) {
        error.push("La fecha de alta es obligatoria")
    }
    if (!schema.perteneciente) {
        error.push("Es obligatorio seleccionar un perteneciente")
    }


    // if (!schema.password) { error.push("La contraseña es obligatoria") }
    return error
}
