import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {createApi, getSales349Api, getSalesApi, updateSalesApi} from "../../../../services/Admin/sale";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import { Button, Grid, Paper, TextField } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import InputSelect from "../../../../components/Inputs/InputSelect";
import { showOrganizationsApi } from "../../../../services/Admin/organization";
import InputComplexAutocomplete from "../../../../components/Inputs/InputComplexAutocomplete";

export default function Edit() {
    const { id } = useParams()
    const { logout } = useAuth()
    const history = useHistory()
    const [msgError, setMsgError] = useState([])
    const [state, setState] = useState(initialValues)
    const [optionsOrganization, setOptionsOrganization] = useState([])
    const [loadingOrganization, setLoadingOrganization] = useState(false)
    const [nameOrganizationFilter, setNameOrganizationFilter] = useState("")

    const curYear = new Date().getFullYear()
    let optionsSaleYear = []
    for (let i = 2022; i <= curYear; i++) {
        optionsSaleYear.push({ [i]: i })
    }

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        /* void getOrganization() */
        if (id) {
            void getSale()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getSale = async () => {
        const response = await getSales349Api(logout, id)
        if (response.status) {
            setState(initialValues(response.data))
        }
    }
    const handleChangeGetOrganizations = (event) => {
        setNameOrganizationFilter(event.target.name)
        state["organization"] = event.target.value
        setState({ ...state })
    }

    useEffect(() => {
        setLoadingOrganization(false)
        setOptionsOrganization([])

        if (nameOrganizationFilter.length > 0) {
            setLoadingOrganization(true)
            const timeOutId = setTimeout(() => {
                getOrganization(nameOrganizationFilter)
            }, 1500)
            return () => clearTimeout(timeOutId)
        }

    }, [nameOrganizationFilter])


    const getOrganization = async (nif) => {

        const response = await showOrganizationsApi(logout, { id, nif })
        if (response.status) {
            let organizations = response.data
            organizations = organizations.map(organization => {
                return { id: organization.id, name: organization.nif + " | " + organization.name + " | " + organization.nameChinese }
            })
            setOptionsOrganization(organizations)
            setLoadingOrganization(false)
        } else {
            setOptionsOrganization([])
            setLoadingOrganization(false)
        }
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({ ...state })
    }
    const handleChangeNumber = (event) => {
        if (event.target.value !== "") {
            state[event.target.name] = parseFloat(event.target.value)
            setState({ ...state })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        if (id) {
            void updateSale()
        } else {
            void createSale()
        }
    }
    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({ ...state })
    }

    const updateSale = async () => {
        setMsgError([])

        const response = await updateSalesApi(logout, state)
        if (response.status) {
            history.push("/admin/sales390/management")
        } else {
            setMsgError(response.errors)
        }
    }

    const createSale = async () => {
        createApi(state).then(resp => {
            history.push("/admin/sales390/management")
        }).catch(error => {
            setMsgError(error.response.data.errors)

        })
    }

    return (
        <>
            <HeaderPage title="Configuración de la venta 390" />

            <MsgError msgError={msgError} />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">Ajustes</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <InputComplexAutocomplete
                                    label="Organización"
                                    name="organization"
                                    options={optionsOrganization}
                                    loading={loadingOrganization}
                                    required
                                    value={state?.organization || null}
                                    setValue={setOptionsOrganization}
                                    onChange={handleChangeGetOrganizations}
                                />
                            </Grid>

                            <Grid item xs={6} md={6} lg={6}>
                                <InputSelect
                                    label="Año"
                                    name="year"
                                    required
                                    options={optionsSaleYear}
                                    value={state?.year}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <Button
                        type="button"
                        href="/admin/sales390/management"
                        fullWidth
                    >
                        <ArrowBackIos />
                        Volver al listado
                    </Button>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        onClick={handleSubmit}
                    >
                        {id ? "Modificar" : "Crear"}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}


function initialValues(props) {

    return {
        id: props?.id || "",
        organization: props?.organization?.id || "",
        year: props?.year || "",
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.organization) {
        error.push("Es obligatorio escoger una organizacion a la que assignar la venta")
    }
    if (!schema.year) {
        error.push("Es obligatorio escoger un año al que assignar la venta")
    }

    return error
}
