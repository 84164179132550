import React from "react";
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";

/**
 * Muestra un selector
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **options** *object*
 * - **onChange** *function*
 * - **multiple** *bool*
 * - **disabled** *bool*
 *
 * ## EJ
 * options: [{id: 1, name: "Aaaa"}, {id: 2, name: "Bbbbb"}]
 */
export default function InputSelect2(props) {

    const {label, name, value, options, onChange, required, multiple, disabled} = props

    return (
        <FormControl fullWidth size={"small"} required={required}>
            <InputLabel id="select-label" shrink={value !== null && value !== ""}>
                {label}
            </InputLabel>
            <Select
                input={<OutlinedInput notched={value !== null && value !== ""} label={label} />}
                value={value}
                labelId="select-label"
                label={required ? `${label} * ` : {label}}
                name={name}
                required={required}
                size="small"
                disabled={disabled}
                displayEmpty={value !== null && value !== ""}
                multiple={multiple}
                onChange={onChange}>
                <MenuItem value=""><span style={{color: "#828384"}}>Selecciona un elemento</span></MenuItem>
                {options.map((option, key) => {
                    return (<MenuItem key={key} value={option.id}>{option.name}</MenuItem>)
                })}
            </Select>
        </FormControl>
    )
}