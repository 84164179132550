import React from "react";
import {Grid} from "@mui/material";

export default function HeaderPage(props) {
    const {title, children} = props

    return (
        <Grid container justifyContent="flex-start" spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <h1 className="p-0">{title}</h1>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                {children}
            </Grid>
        </Grid>
    )
}
