import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {Delete, Get, Post, Put} from "../../utils/request";
import {getToken} from "../Auth/token";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}`;
const token = `Bearer ${getToken()}`;

export async function createApi(formData) {
    const url = `${baseUrl}/epigrafe/create`;
    const ret = await Post(url, null, formData, token);
    return ret;
}

export async function showEpigrafesApi(logout, params) {
    const url = `${baseUrl}/epigrafe/list`;
    const ret = await Get(url, logout, token, params);
    return ret;
}

export async function getEpigrafeApi(logout, id) {
    const url = `${baseUrl}/epigrafe/${id}`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function updateEpigrafeApi(logout, data) {
    const url = `${baseUrl}/epigrafe/update`;
    const ret = await Put(url, logout, data, token);
    return ret;
}
export async function deleteEpigrafeApi(logout, id) {
    const url = `${baseUrl}/epigrafe/delete/${id}`;
    const ret = await Delete(url, logout, token);
    return ret;
}
