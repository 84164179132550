import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersManagement from "../../../containers/Admin/Dashboard/Management"
import {Helmet} from "react-helmet";
import React from "react";
import CheckRole from "../../../components/Auth/checkRole";
import useAuth from "../../../hooks/useAuth";

export default function Management(props) {

        return (
            <Basic menu="dashboard">
                <CheckRole roles={props.roles}/>
                <Helmet>
                    <title>Dashboard Management</title>
                </Helmet>
                <ContainersManagement/>
            </Basic>
        )
}
