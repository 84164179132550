import React, { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import { Button, Grid, Paper, Checkbox, OutlinedInput } from "@mui/material";
import {deleteSale390Api, deleteSales390Api, exportSalesApi, showSales390Api} from "../../../../services/Admin/sale";
import { Delete, Edit } from "@mui/icons-material";
import OwsTable from "../../../../components/Admin/OwsTable";
import PopupDelete from "../../../../components/PopupDelete";
import MsgSuccess from "../../../../components/MsgSuccess";
import MsgError from "../../../../components/MsgError";
import InputSelect2 from "../../../../components/Inputs/InputSelect2";
import InputText from "../../../../components/Inputs/InputText";
import { DASH_MAX_ROW } from "../../../../utils/constants";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
export default function Management() {
    const [sales, setSales] = useState([])
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const [state, setState] = useState(initialValues)
    const [quantity, setQuantity] = useState(0)
    const { logout } = useAuth()
    const [selection, setSelection] = useState([])
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
        message: null
    });


    useEffect(() => {
        setSales([])
        const timeOutId = setTimeout(() => {
            if (state.year) {
                getSalesData()
            }
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])


    function getSalesData() {
        setLoading(true);
        setMsgError([])
        // Renombramos en caso de lo que columna no coincida con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            nif: "",
            clientCode: "",
            year: "",

        }

        if (state.nif) { params.nif = state.nif }
        if (state.clientCode) { params.clientCode = state.clientCode }
        if (state.year) { params.year = state.year }

        showSales390Api(logout, params)
            .then(data => {
                let position = {}

                data.data.forEach((doc, key) => {
                    position[`${key}`] = false
                })

                setSalesData(data.data)
                setQuantity(data.count)
                setLoading(false)
            })
            .catch(error => {
                setMsgError([error?.response?.data.message])
                setLoading(false)
                setSales([])
            });
    }

    function setSalesData(snapshot) {

        const temp = snapshot.map((doc) => {
            const href = `/admin/sales390/edit/${doc.id}`

            return {
                organizationName: doc?.organization?.name,
                organizationNif: (<a href={href}>{doc?.organization?.nif}</a>),
                organizationClientCode: doc?.organization?.clientCode,
                organizationNameChinese: doc?.organization?.nameChinese,
                notified: (doc?.isNotified == 1) ? "Si" : "No",
                actions: (
                    <Grid container>
                        <Grid item xs={6}>
                            <Button variant="text" color="primary" href={href}><Edit /></Button>
                        </Grid>
                        {!doc.deleted && (
                            <Grid item xs={6}>
                                <Button variant="text" onClick={() => {
                                    handleDelete(doc.id)
                                }}><Delete /></Button>
                            </Grid>
                        )}

                    </Grid>
                ),
                ...doc,
            }
        })
        setSales(temp)
        setLoading(false)
    }

    const handleDelete = (id) => {
        setPopup({
            show: true,
            id,
        });
    };

    const handleDeleteTrue = () => {
        if (popup.show && popup.id) {

            const idx = popup.id


            const request = (typeof idx === "object") ? deleteSales390Api(logout, popup.id) : deleteSale390Api(logout, popup.id)

            request.then(response => {
                setMsgSuccess(response.data)
                getSalesData()
                setSelection([])
            }).catch(error => {
                setMsgError([error.response.data.errors])
            })
            setPopup({
                show: false,
                id: null,
            });
        }
    };

    const handleDeleteFalse = () => {
        setPopup({
            show: false,
            id: null,
        });
    };

    const handleBulkDelete = () => {

        setPopup({
            show: true,
            id: selection,
            message: "¿Estás seguro de querer eliminar estos registros?"
        })

    }

    const exportData = () => {
        setLoadingExport(true)
        exportSalesApi(logout, { "nif": state.nif, "clientCode": state.clientCode, "year": state.year, "model": "390" }).then(response => {
            window.open(response.data, '_blank');
            setLoadingExport(false)
        }).catch(error => {
            setMsgError([error.response.data.errors])
            setLoadingExport(false)
        })

    }

    const handleMarkAsNotified = () => {

        markAsNotifiedApi(logout, selection).then(response => {
            setMsgSuccess(response.data)
            getSalesData()
        }).catch(error => {
            setMsgError([error.response.data.errors])
        })

    }
    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }


    return (
        <>
            {popup.show && (
                <PopupDelete
                    show={popup.show}
                    handleDeleteTrue={handleDeleteTrue}
                    handleDeleteFalse={handleDeleteFalse}
                    message={popup.message}
                />
            )}
            <Grid container xs={12} spacing={3} >
                <Grid item xs={4}>
                    <h1 style={{marginLeft: 25}}>Ventas 390</h1>
                </Grid>
                <Grid item xs={4} style={{ margin: "25px 0px" }}>
                    <LoadingButton
                        type="button"
                        color="primary"
                        loading={loadingExport}
                        loadingPosition="start"
                        variant="outlined"
                        fullWidth
                        onClick={exportData}
                    >
                        Exportar
                    </LoadingButton>
                </Grid>
                <Grid item xs={4} style={{ margin: "25px 0px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        href="/admin/sales390/create">
                        + Añadir
                    </Button>
                </Grid>
                {(selection.length > 0 || state.checkAll) && (
                <>
                <Grid item xs={6}></Grid>
                    <Grid item xs={3} style={{paddingTop: 0}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleBulkDelete}>
                            Eliminar
                        </Button>
                    </Grid>
                    <Grid item xs={3} style={{paddingTop: 0}}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleMarkAsNotified}>
                            Notificar
                        </Button>
                    </Grid>
                </>
                )}
            </Grid>


            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgSuccess msgSuccess={msgSuccess} />
                    <MsgError msgError={msgError} />
                </Grid>

                <Grid item xs={12}>

                    <Paper sx={{ p: 2, m: 3, display: 'flex', flexDirection: 'column' }}>

                        <Filters
                            state={state}
                            setState={setState}
                            setSelection={setSelection}
                            sales={sales}
                        />
                        <OwsTable
                            columns={tableColumns(handleChangeSelection, handleDelete, selection, state)}
                            rows={sales}
                            rowCount={quantity}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        clientCode: "",
        nif: "",
        checkAll: false,
    }
}

function tableColumns(handleChangeSelection, handleDelete, selection, state) {
    return [
        {
            field: "checkbox",
            headerName: "",
            renderCell: (params) => {
                const checked = !state.checkAll ? selection.includes(params.row.id) : true
                return (<Checkbox onChange={handleChangeSelection} value={params.row.id} checked={checked} />)
            },
        },
        {
            headerName: "COD. CLIENTE",
            field: "organizationClientCode",
            width: 100
        },
        {
            headerName: "ORGANIZACIÓN",
            field: "organizationName",
            width: 400,
        },
        {
            headerName: "AÑO",
            field: "year",
            width: 100,
        },
        {
            headerName: "PDF",
            field: "saleAeatPdf",
            width: 100,
            renderCell: (params) => {
                return <a href={params.row.saleAeatPdf} target={"_blank"}><PictureAsPdfIcon/></a>;
            },
        },
        {
            headerName: "ACCIONES",
            field: "actions",
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/admin/sales390/edit/${params.row.id}`}><Edit /></Link>
                        {!params.row.deleted && (
                            <Button variant="text" onClick={() => {
                                handleDelete(params.row.id)
                            }}><Delete /></Button>
                        )}
                    </>
                )
            },

        }

    ]
}

function Filters(props) {

    const { state, setState, setSelection, sales } = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({ ...state })
    }

    const handleChangeCheckAll = (event) => {
        let tempSelection = []
        state[event.target.name] = event.target.checked
        if (!event.target.checked) {
            setSelection([])
        }else{
            sales.forEach(item => {
                item.checked = true
                tempSelection.push(item.id)
            })
            setSelection(tempSelection)
        }
        setState({...state})
    }


    const curYear = new Date().getFullYear()
    let optionsSaleYear = []
    for (let i = 2022; i <= curYear; i++) {
        optionsSaleYear.push({ id: i, name: i })
    }
    return (
        <Grid container spacing={1} style={{ marginBottom: '20px' }}>
            <Grid item xs={6}>
                <InputSelect2
                    label={"Año"}
                    name="year"
                    onChange={handleChange}
                    value={state?.year}
                    options={optionsSaleYear}>
                </InputSelect2>
            </Grid>
            <Grid item xs={6}>
                <InputText
                    label="Código cliente"
                    name="clientCode"
                    value={state.clientCode}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <InputText
                    label="NIF"
                    name="nif"
                    onChange={handleChange}
                    value={state.nif}
                />
            </Grid>

            <Grid item xs={6}>
                <Checkbox name="checkAll" onChange={handleChangeCheckAll} checked={state.checkAll} /> Marcar todos los registros
            </Grid>
        </Grid>
    )
}
