import Header from "../Header"


export default function Basic(props) {
    const { children } = props;
    return (
        <div>
            <Header/>
            {children}
        </div>
    );
}