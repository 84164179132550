import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {Delete, Get, Post, Put} from "../../utils/request";
import {getToken} from "../Auth/token";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}`;
const token = `Bearer ${getToken()}`;

export async function createApi(formData) {
    const url = `${baseUrl}/organization/create`;
    const ret = await Post(url, null, formData, token);
    return ret;
}

export async function showOrganizationsApi(logout, params) {
    const url = `${baseUrl}/organization/list`;
    return await Get(url, logout, token, params);
}

export async function getOrganizationsApi(logout, id) {
    const url = `${baseUrl}/organization/${id}`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function updateOrganizationsApi(logout, data) {
    const url = `${baseUrl}/organization/update`;
    const ret = await Put(url, logout, data, token);
    return ret;
}

export async function importOrganizationsApi(logout, data) {
    const url = `${baseUrl}/organization/import-excel`;
    const ret = await Post(url, logout, data, token);
    return ret;
}

export async function showImportOrganizationsApi(logout) {
    const url = `${baseUrl}/organization/list-import`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function loadImportOrganizationsApi(logout) {
    const url = `${baseUrl}/organization/data-import`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function deleteOrganizationApi(logout, id) {
    const url = `${baseUrl}/organization/delete/${id}`;
    const ret = await Delete(url, logout, null, token)
    return ret;
}

export async function deleteOrganizationsApi(logout, ids) {
    const url = `${baseUrl}/organization/delete`;
    const ret = await Delete(url, logout, ids, token)
    return ret;
}