import Basic from "../../containers/User/Layouts/Basic"
import LoginForm from "../../containers/Auth/LoginForm"

export default function Login() {
  return (
    <Basic>
        <LoginForm/>
    </Basic>
  )
}
