import Basic from "../../../containers/Admin/Layouts/Basic";
import Component from "../../../containers/Admin/Organization/Import";
import {Helmet} from "react-helmet";
import React from "react";
import CheckRole from "../../../components/Auth/checkRole";
import useAuth from "../../../hooks/useAuth";

export default function Import() {

    const {auth} = useAuth();

    if (!auth) {
        history.push("/login");
    }

    return (
    <Basic menu="organizations-import">
      <Helmet>
        <title>Importación - Organizaciones</title>
      </Helmet>
      <Component />
    </Basic>
  );
}
