import useAuth from '../../../../../hooks/useAuth';

export default function Menu() {

    const {auth} = useAuth()

    const role = auth ? auth.role : null


    return (
        <div className="menu">
        </div>
    );
}