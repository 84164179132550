import React, {useState} from "react";
import {registerApi} from "../../../services/Auth/login";
import {useHistory} from "react-router-dom";
import {Button, Checkbox, CssBaseline, FormControlLabel, TextField} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {ThemeProvider} from "@emotion/react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {createTheme} from "@mui/material/styles";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const theme = createTheme();

export default function RegisterForm() {

    const history = useHistory()

    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        const response = await registerApi(state)
        if (response.status) {
            setState(initialValues())
            history.push("/login")
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="name"
                                    required
                                    fullWidth
                                    id="name"
                                    label="First Name"
                                    autoFocus
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="surnames"
                                    label="Last Name"
                                    name="surnames"
                                    autoComplete="family-name"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Sign Up
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
        /*<div className="register-form">

            <h1>Estoy en register</h1>

            <MsgError msgError={msgError} />

            <form onSubmit={handleSubmit} >
                <div>
                    <InputText
                        label="Nombre"
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                    />
                </div>

                <br />

                <div>
                    <InputText
                        label="Apellidos"
                        name="surnames"
                        value={state.surnames}
                        onChange={handleChange}
                    />
                </div>

                <br />
                
                <div>
                    <InputText
                        label="Correo electrónico"
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                    />
                </div>

                <br />
                
                <div>
                    <InputPassword
                        label="Contraseña"
                        name="password"
                        value={state.password}
                        onChange={handleChange}
                    />

                </div>

                <br/>

                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Registrar
                    </Button>
                </div>

            </form>
        </div>*/
    );
}

function initialValues() {
    return {
        name: "",
        surnames: "",
        email: "",
        password: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.name) {
        error.push("El nombre es obligatorio")
    }
    if (!schema.email) {
        error.push("El email es obligatorio")
    }
    if (!schema.password) {
        error.push("La contraseña es obligatoria")
    }

    return error
}