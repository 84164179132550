import Basic from "../../containers/User/Layouts/Basic"
import ForgottenPasswordForm from "../../containers/Auth/ForgottenPassword"

export default function ForgottenPassword() {
    return (
        <Basic>
            <ForgottenPasswordForm/>
        </Basic>
    )
}
