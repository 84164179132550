import TopBar from "./TopBar"
import Menu from "./Menu"
import useAuth from "../../../../hooks/useAuth";

export default function Header() {
    const { auth } = useAuth()

    return (
        <div className="header">
            {auth && (
                <TopBar />
            )}
            <Menu/>
        </div>
    );
}