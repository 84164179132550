import React, {useEffect, useMemo, useState} from "react"
import {getToken, hasExpiredToken, removeToken, setToken} from "./services/Auth/token"
import jwtDecode from "jwt-decode"
import {useHistory, useLocation} from "react-router-dom";
import AuthContext from "./context/AuthContext"

export default function Home(props) {

    const [auth, setAuth] = useState(undefined)
    const [reloadUser, setReloadUser] = useState(false)
    const history = useHistory();
    const location = useLocation();

    // Mantener los datos del usuario al recargar
    useEffect(() => {

        const token = getToken()

        if (token) {

            let roleName = "";
            switch (jwtDecode(token).roles[0]) {
                case "ROLE_ADMINISTRATOR":
                    roleName = "Administrador"
                    break;
                case "ROLE_USER":
                    roleName = "Usuario"
                    break;
                default:
                    roleName = "Usuario"
            }

            if (hasExpiredToken()) {
                history.push("/logout")
            }

            setAuth({
                token: token,
                idUser: jwtDecode(token).id,
                role: jwtDecode(token).roles[0],
                roleName: roleName,
                name: jwtDecode(token).name,
                surnames: jwtDecode(token).surnames,
            })

        } else {
            setAuth(null)
            if (!location.pathname.includes("register") && !location.pathname.includes("forgot-password") && !location.pathname.includes("recover-password")) {
                history.push("/login")
            }
        }

        // setReloadUser(false)
    }, [reloadUser, history])  // eslint-disable-line react-hooks/exhaustive-deps

    // Guardar los datos del usuarios, useMemo evita que vaya recargando si no se modifica algun dato
    const login = (token) => {
        setToken(token)
        let roleName = ""
        switch (jwtDecode(token).roles[0]) {
            case "ROLE_ADMINISTRATOR":
                roleName = "Administrador"
                break;
            case "ROLE_USER":
                roleName = "Usuario"
                break;
            default:
                roleName = "Usuario"
        }
        setAuth({
            token: token,
            idUser: jwtDecode(token).id,
            role: jwtDecode(token).roles[0],
            roleName: roleName,
            name: jwtDecode(token).name,
            surnames: jwtDecode(token).surnames,
        })
        history.push('/admin/sales/management')
    }

    const logout = () => {
        if (auth) {
            removeToken()
            setAuth(null)
            history.push("/login")
        }
    }

    const authData = useMemo(
        () => ({
            auth: auth,
            login: login,
            logout: logout,
            setReloadUser: setReloadUser
        }), [auth]  // eslint-disable-line react-hooks/exhaustive-deps
    )

    if (auth === undefined) {
        return null
    }

    return (
        <AuthContext.Provider value={authData}>
            {props.children}
        </AuthContext.Provider>
    )
}
