import { BASE_PATH, BASE_PATH_EXTENSION } from "../../utils/constants";
import { Post } from "../../utils/request";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION}`;

export async function loginApi(formData) {
    const url = `${BASE_PATH}/api/login_check`;
    const ret = await Post(url, null, formData, null);
    return ret;
}

export async function registerApi(formData) {
    const url = `${baseUrl}/auth/register`;
    const ret = await Post(url, null, formData, null);
    return ret;
}

export async function sendRecoveryEmail(formData) {
    const url = `${baseUrl}/auth/send-recovery-email`;
    const ret = await Post(url, null, formData, null);
    return ret;
}

export async function recoverPassword(formData, token) {
    const url = `${baseUrl}/auth/recover-password/${token}`;
    const ret = await Post(url, null, formData, null);
    return ret;
}