import React from "react";
import {useFilters, useGlobalFilter, useMountedLayoutEffect, usePagination, useRowSelect, useTable} from "react-table";
import {matchSorter} from "match-sorter";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    MenuItem,
    NativeSelect,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    paper: {
        textAlign: "center",
        overflow: "auto",
    },
}));

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <Checkbox ref={resolvedRef} {...rest} />
            </>
        );
    }
);

export default function TableFilterBase({
                                            columns,
                                            data,
                                            search,
                                            selectable,
                                            selectedRows = [],
                                            onSelectedRowsChange,
                                            selectTitle,
                                            setLimit = () => {},
                                            setOffset = () => {},
                                            offset = null,
                                            limit
                                        }) {

    // Define a default UI for filtering
    function DefaultColumnFilter({column: {filterValue, preFilteredRows, setFilter}}) {
        const count = preFilteredRows.length;

        return (
            <TextField
                variant="outlined"
                placeholder={`Search ${count} records...`}
                type="search"
                fullWidth
                size="small"
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
            />
        );
    }

    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, {
            keys: [(row) => row.values[id]],
        });
    }

    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = (val) => !val;

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            minWidth: 30,
            width: 150,
            maxWidth: 200,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            dateBetween: dateBetweenFilterFn /*<- LIKE THIS*/,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const props = useTable(
        {
            columns,
            data,
            initialState: {
                selectedRowIds: selectedRows,
                pageSize: 100,
            },
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
        },
        useFilters, // useFilters!
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            if (selectable) {
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({getToggleAllPageRowsSelectedProps}) => (
                            <div>
                                <>
                                    {selectTitle}<br/>
                                    <span>Todos</span>
                                    <IndeterminateCheckbox
                                        {...getToggleAllPageRowsSelectedProps()}
                                    />
                                </>
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
            }
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        gotoPage,
        state: {pageIndex, pageSize, globalFilter, selectedRowIds},
    } = props;

    useMountedLayoutEffect(() => {
        onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
    }, [onSelectedRowsChange, selectedRowIds]);

    React.useEffect(() => {
    }, [globalFilter]);

    const classes = useStyles();

    function dateBetweenFilterFn(rows, id, filterValues) {
        let sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
        let ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

        if (ed || sd) {
            return rows.filter((r) => {
                const time = new Date(r.values[id]);

                if (ed && sd) {
                    return time >= sd && time <= ed;
                } else if (sd) {
                    return time >= sd;
                } else if (ed) {
                    return time <= ed;
                }
                return time;
            });
        } else {
            return rows;
        }
    }

    return (
        <>
            {search && (
                <Box m={2}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscador"
                        label="Buscador"
                        type="search"
                        fullWidth
                        value={globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                </Box>
            )}
            <Box m={0} style={{overflow: "auto"}}>
                <Table {...getTableProps()}>
                    <TableHead style={{background: "#f1f2f6"}}>
                        {headerGroups.map((headerGroup, key) => (
                            <TableRow
                                {...headerGroup.getHeaderGroupProps()}
                                key={key}
                            >
                                {headerGroup.headers.map((column, key) => (
                                    <TableCell
                                        {...column.getHeaderProps()}
                                        key={key}
                                        style={column.disableFilters ? {
                                            verticalAlign: "top",
                                            minWidth: column.width ?? "100px"
                                        } : {verticalAlign: "inherit", minWidth: column.width ?? "100px"}}
                                    >

                                        {column.render("Header")}
                                        {/* Render the columns filter UI */}

                                        {column.canFilter
                                            ? (<div>{column.render("Filter")}</div>)
                                            : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        <tr>
                            <th
                                colSpan={visibleColumns.length}
                                style={{
                                    textAlign: "left"
                                }}
                            ></th>
                        </tr>
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row, key) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()} key={key}>
                                    {row.cells.map((cell, key) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                key={key}
                                            >
                                                {cell.render("Cell")}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    {headerGroups && (
                        <TableFooter style={{background: "#f1f2f6"}}>
                            {headerGroups.map((headerGroup, key) => (
                                <TableRow
                                    {...headerGroup.getHeaderGroupProps()}
                                    key={key}
                                >
                                    {headerGroup.headers.map((column, key) => (
                                        <TableCell
                                            {...column.getHeaderProps()}
                                            key={key}
                                            style={{
                                                fontWeight: 500,
                                                color: "rgba(0, 0, 0, 0.87)",
                                            }}
                                        >
                                            {column.render("Header")}
                                            {/* Render the columns filter UI */}
                                            <div>
                                            </div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableFooter>
                    )}
                </Table>
            </Box>
            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={2} md={2} lg={2}>
                        <NativeSelect
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((pageSize, index) => (
                                <option key={pageSize} value={pageSize}>
                                    Mostrar {pageSize}
                                </option>
                            ))}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={8} md={8} lg={8}>
                        <Paper className={classes.paper} elevation={0}>
                            {canPreviousPage ? (
                                <Button onClick={() => previousPage()}>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Button>
                            ) : (
                                <Button disabled>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Button>
                            )}

                            {canNextPage ? (
                                <Button
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Button>
                            ) : (
                                <Button disabled>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Button>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        Página <strong><input defaultValue={pageIndex + 1} onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                        if (offset !== null) {
                            setOffset(offset + 100)
                        }
                    }} style={{width: '50px'}}/></strong> de {" "}
                        <strong> {pageOptions.length} </strong>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
