import Basic from "../../../containers/Admin/Layouts/Basic"
import ComponentFields from "../../../containers/Admin/Epigrafe/Fields"
import {Helmet} from "react-helmet";
import React from "react";
import CheckRole from "../../../components/Auth/checkRole";
import useAuth from "../../../hooks/useAuth";

export default function Create(props) {

    const {auth} = useAuth();

    if (!auth) {
        history.push("/login");
    }

    return (
        <Basic menu="epigrafes">
            <CheckRole roles={props.roles}/>
            <Helmet>
                <title>Epigrafe Create</title>
            </Helmet>
            <ComponentFields/>
        </Basic>
    )
}
