import Basic from "../../containers/User/Layouts/Basic"
import CheckRole from "../../components/Auth/checkRole"
import useAuth from "../../hooks/useAuth"
import {useHistory} from "react-router-dom";

export default function Homepage(props) {
    const { auth } = useAuth();
    const history = useHistory();

    if (!auth) {
        history.push("/login");
    } else {
        history.push("/admin/sales/management")
    }

    return (
        <Basic>
            <CheckRole roles={props.roles} />
        </Basic>
    )
}
