import {Get} from "../../utils/request";
import {getToken} from "../Auth/token";
import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}`;

export async function showModelsApi(logout, params) {
    let url = `${baseUrl}/dashboard/list` ;

    return await Get(url, logout, `Bearer ${getToken()}`, params);
}

export async function showOrganizationsApi(logout, params) {
    let url = `${baseUrl}/dashboard/orgs` ;

    return await Get(url, logout, `Bearer ${getToken()}`, params);
}