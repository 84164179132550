import React, {useEffect, useMemo, useState} from "react";
import {showUsersApi} from "../../../../services/Admin/user";
import useAuth from "../../../../hooks/useAuth";
import moment from "moment";
import {Button, Grid, Link, Paper} from "@mui/material";
import AsyncTableComp from "../../../../components/TableComponents/AsyncTableComp";
import InputText from "../../../../components/Inputs/InputText";
import InputSelect from "../../../../components/Inputs/InputSelect";

export default function Management() {
    const [users, setUsers] = useState([])
    const [state, setState] = useState(initialState)
    const [quantity, setQuantity] = useState(0)
    const optionsRole = [{ROLE_ADMINISTRATOR: "Administrador"}, {ROLE_GESTOR: "Gestor"}, {ROLE_CUSTOMER: "Cliente"}]
    const optionsAccess = [{si: "Si"}, {no: "No"}]
    const {logout} = useAuth()

    const columns = useMemo(
        () => [
            {
                Header: "ÚLTIMA CONEXION",
                accessor: "active",
                disableFilters: true
            },
            {
                Header: "TIPO DE USUARIO (ROL)",
                accessor: "roleName",
                disableFilters: true
            },
            {
                Header: "NOMBRE",
                accessor: "name",
                disableFilters: true
            },
            {
                Header: "EMAIL",
                accessor: "email",
                disableFilters: true
            },
            {
                Header: "ACCESO A LA PLATAFORMA",
                accessor: "access",
                disableFilters: true,
                width: "10px"
            },
            {
                Header: "ACCIONES",
                accessor: "actions",
                disableFilters: true,
                width: "50px"
            }
        ],
        []
    )


    const getUsersData = (params) => {
        showUsersApi(logout, params)
            .then(data => {
                setUsersData(data.data)
                setQuantity(Math.ceil(data.count / 100))
            })
    }

    const setUsersData = (snapshot) => {
        const temp = snapshot.map((doc) => {
            const href = `/admin/users/edit/${doc.id}`

            return {
                roleName: doc?.role?.name,
                access: doc.isActive ? "Si" : "No",
                active: moment.unix(doc.lastActivity).format("D/M/Y"),
                actions: (
                    <Link color="primary" href={href}>Edit</Link>
                ),
                ...doc,
            }
        })
        setUsers(temp)
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getUsersData({limit: 100, offset: 0, ...state})
        }, 300);

        return () => clearTimeout(delayDebounceFn)
    }, [state])
    
    return (
        <>

            <Grid item xs={6}>
                <h1>Usuarios</h1>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{float: "right", margin: "25px 0px"}}
                    href="/admin/users/create"
                >
                    + Añadir
                </Button>
            </Grid>

            <Grid item xs={4}>
                <InputText
                    label="Email"
                    name="email"
                    required
                    value={state?.email}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={4}>
                <InputSelect
                    label="Tipo de usuario (Rol)"
                    name="role"
                    required
                    onChange={handleChange}
                    value={state?.role}
                    options={optionsRole}
                />
            </Grid>

            <Grid item xs={4}>
                <InputSelect
                    label="Acceso a la plataforma"
                    name="access"
                    required
                    onChange={handleChange}
                    value={state?.access}
                    options={optionsAccess}
                />
            </Grid>

            <Grid item xs={12}>
                <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                    <AsyncTableComp
                        columns={columns}
                        data={users}
                        fetchDataApi={getUsersData}
                        controlledPageCount={quantity}
                    />
                </Paper>
            </Grid>
        </>
    );
}

function initialState() {
    return {
        email: '',
        role: '',
        access: '',
    }
}
