import React from "react";
import {FormControl, InputLabel, MenuItem, Select, OutlinedInput} from "@mui/material";

export default function InputSelect(props) {

    const {label, name, value, options, onChange, required} = props

    return (
        <FormControl fullWidth size="small">
            <InputLabel id="select-label" shrink={value !== null && value !== ""}>
                {label}
            </InputLabel>
            <Select
                input={<OutlinedInput notched={value !== null && value !== ""} label={label}/>}
                labelId="select-label"
                value={value}
                label={label}
                name={name}
                displayEmpty={value !== null && value !== ""}
                onChange={onChange}
                
            >
                {options.map((option, key) => {
                    return (<MenuItem key={key}
                        value={Object.keys(option)[0]}>{Object.values(option).toString()}</MenuItem>)
                })}
            </Select>
        </FormControl>
    )
}
