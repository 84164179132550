import Basic from "../../containers/User/Layouts/Basic"
import RecoverPasswordForm from "../../containers/Auth/RecoverPassword"

export default function RecoverPassword() {
    return (
        <Basic>
            <RecoverPasswordForm/>
        </Basic>
    )
}
