import Basic from "../../../containers/Admin/Layouts/Basic"
import Component from "../../../containers/Admin/Model/Generate"
import {Helmet} from "react-helmet";
import React from "react";
import useAuth from "../../../hooks/useAuth";

export default function Generate() {

    const {auth} = useAuth();

    if (!auth) {
        history.push("/login");
    }

    return (
        <Basic menu="model-generate">
            <Helmet>
                <title>Generar Modelo</title>
            </Helmet>
            <Component />
        </Basic>
    )
}
