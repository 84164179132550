import React, {useState} from 'react'
import {
    useAsyncDebounce,
    useFilters,
    useGlobalFilter,
    useMountedLayoutEffect,
    usePagination,
    useRowSelect,
    useTable
} from 'react-table'
import {
    Box,
    Button, Checkbox,
    Grid,
    NativeSelect,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    paper: {
        textAlign: "center",
        overflow: "auto",
    },
}));

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <Checkbox ref={resolvedRef} {...rest} />
            </>
        );
    }
);

export default function AsyncTableComp({
                                           columns,
                                           data,
                                           selectable,
                                           selectedRows = [],
                                           onSelectedRowsChange,
                                           selectTitle,
                                           fetchDataApi,
                                           controlledPageCount
                                       }) {


    const [limit, setLimit] = useState(100)
    const [offset, setOffset] = useState(0)
    const skipPageResetRef = React.useRef()

    function DefaultColumnFilter({column: {filterValue, preFilteredRows, setFilter}}) {
        const count = preFilteredRows.length

        return (
            <TextField
                variant="outlined"
                placeholder={`Search ${count} records...`}
                type="search"
                fullWidth
                size="small"
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
            />
        );
    }


    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const classes = useStyles();

    const filterTypes = React.useMemo(
        () => ({
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize, selectedRowIds},
    } = useTable(
        {
            columns,
            data: data,
            defaultColumn, // Be sure to pass the defaultColumn option
            initialState: {
                pageSize: 100
            },
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            pageCount: controlledPageCount,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            if (selectable) {
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({getToggleAllPageRowsSelectedProps}) => (
                            <div>
                                <>
                                    {selectTitle}<br/>
                                    <span>Todos</span>
                                    <IndeterminateCheckbox
                                        {...getToggleAllPageRowsSelectedProps()}
                                    />
                                </>
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
            }
        }
    )

    useMountedLayoutEffect(() => {
        onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
    }, [onSelectedRowsChange, selectedRowIds]);

    const onFetchDataDebounced = useAsyncDebounce(({limit, offset}) => {
        skipPageResetRef.current = true
        fetchDataApi({limit, offset})
    }, 100)

    React.useEffect(() => {

        skipPageResetRef.current = false
        void onFetchDataDebounced({limit, offset})

    }, [onFetchDataDebounced, pageIndex, pageSize]);

    return (
        <>
            <Box m={0} style={{overflow: "auto"}}>
                <Table {...getTableProps()}>
                    <TableHead style={{background: "#f1f2f6"}}>
                        {headerGroups.map((headerGroup, key) => (
                            <TableRow
                                {...headerGroup.getHeaderGroupProps()}
                                key={key}
                            >
                                {headerGroup.headers.map((column, key) => (
                                    <TableCell
                                        {...column.getHeaderProps()}
                                        key={key} style={column.disableFilters ? {
                                        minWidth: column.width,
                                        verticalAlign: "inherit"
                                    } : {verticalAlign: "inherit", minWidth: column.width}}>
                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render("Filter") : null}</div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {rows.map((row, key) => {
                            prepareRow(row)
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map((cell, key) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                key={key}
                                            >
                                                {cell.render("Cell")}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={2} md={2} lg={2}>
                        <NativeSelect
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                                setLimit(e.target.value)
                            }}
                        >
                            {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((pageSize, index) => (
                                <option key={pageSize} value={pageSize}>

                                    Mostrar {pageSize}
                                </option>
                            ))}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={8} md={8} lg={8}>
                        <Paper className={classes.paper} elevation={0}>
                            {pageIndex > 0 ? (
                                <Button onClick={() => {
                                    setOffset(offset - 100)
                                    previousPage()
                                }}>
                                        <span role="presentation">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                role="presentation"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    fillRule="evenodd"
                                                    d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                                ></path>
                                            </svg>
                                        </span>
                                </Button>
                            ) : (
                                <Button disabled>
                                        <span role="presentation">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                role="presentation"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    fillRule="evenodd"
                                                    d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                                ></path>
                                            </svg>
                                        </span>
                                </Button>
                            )}

                            {pageIndex < controlledPageCount ? (
                                <Button
                                    onClick={() => {
                                        setOffset(offset + 100)
                                        nextPage()
                                    }}
                                    disabled={!canNextPage}
                                >
                                        <span role="presentation">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                role="presentation"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    fillRule="evenodd"
                                                    d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                                ></path>
                                            </svg>
                                        </span>
                                </Button>
                            ) : (
                                <Button disabled>
                                        <span role="presentation">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                role="presentation"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    fillRule="evenodd"
                                                    d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                                ></path>
                                            </svg>
                                        </span>
                                </Button>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        Página <strong><input defaultValue={pageIndex + 1} onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        setOffset(page * 100)
                        gotoPage(page)
                    }} style={{width: '50px'}}/></strong> de {" "}
                        <strong> {pageOptions.length} </strong>
                    </Grid>
                </Grid>
            </Box>
        </>
    )

}