import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";

export default function InputComplexAutocomplete2(props) {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState(null);
    const [inputName, setInputName] = useState("");
    const { label, name, options, required, disabled, onChange, loading } = props;



    useEffect(() => {
        onChange({ target: { name: inputName, value: inputValue } })
    }, [inputValue, inputName]);

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => {
                let name = option.name.split("|");
                return (
                    <span {...props}>
                        {name.length > 1 && (
                            <>
                                {name.map((item, key) => {
                                    return (
                                        <>
                                            {key === 0 && (
                                                <span style={{ color: 'blue' }}>{item}</span>

                                            )}
                                            {key > 0 && (
                                                <span>{item}</span>

                                            )}
                                            {(key +1) < name.length && (
                                                <span>&nbsp; | &nbsp;</span>
                                            )}

                                        </>
                                    )
                                })}
                            </>

                        )}
                        {name.length === 0 && (
                            <>
                                <span>{name[0]}</span>
                            </>

                        )}
                    </span>
                );
            }}
            options={options}
            loading={loading}
            onChange={(event, value) => setInputValue(value.id)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    size="small"
                    onChange={(event) => {
                        setInputName(event.target.value);
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topFilms = [
    { name: "Aaaa", id: 1 },
    { name: "Bbbb", id: 2 },
];
