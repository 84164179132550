import React, {useState}  from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {Grid} from "@mui/material";

export default function VerticalWizard ({steps, stepsEnableNext, stepsContent}) {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleReset = () => {
        setActiveStep(0);
    }

    return (
         <Grid item xs={12}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                index === (steps.length - 1) ? (
                                    <Typography variant="caption">Paso Final</Typography>
                                ) : null
                            }
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                            <Box sx={{mb: 2, mt: 2}}>
                                <Grid item xs={12}>
                                    {stepsContent[index]}
                                </Grid>
                                <Grid item xs={3} sx={{ mb: 2 }}>
                                    <Button
                                        variant="contained"
                                        disabled={stepsEnableNext[index]}
                                        onClick={handleNext}
                                        sx={{mt: 1, mr: 1}}
                                        className="botonDisable"
                                    >
                                        {index === steps.length - 1 ? 'Finalizado' : 'Siguiente'}
                                    </Button>

                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{mt: 1, mr: 1}}
                                    >
                                        Paso anterior
                                    </Button>
                                </Grid>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{p: 3}}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                </Paper>
            )}
        </Grid>
    );
}
