import useAuth from "../../hooks/useAuth";
import {useEffect} from "react";

export default function CheckRole(props) {

    const {roles} = props

    const {logout, auth} = useAuth()

    useEffect(() => {

        if (roles) {

            if (!roles.includes(auth.role)) {

                logout()

            }

        }

    })

    return(<></>)
}
