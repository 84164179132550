import React, {useEffect, useState} from "react";
import {loginApi} from "../../../services/Auth/login";
import useAuth from "../../../hooks/useAuth";
import {Button, TextField} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MsgError from "../../../components/MsgError";
import image from "../../../assets/logo.png";
import {setToken} from "../../../services/Auth/token";
import pjson from "../../../../package.json";

const theme = createTheme();

export default function LoginForm() {

    const {login} = useAuth()
    const pjson = require("../../../../package.json")
    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])

    useEffect(() => {
        setToken()
    }, []);

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        await loginApi(state).then(response => {
            login(response.token)
        }).catch(error => {
            setMsgError([error.response.data.errors])
        })

    }

    return (<>
        <ThemeProvider theme={theme}>

            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}>
                    <Box
                        component="img"
                        alt="logo Sinolegal"
                        src={image}
                        sx={{
                            height: 233, width: 450, maxHeight: {md: 167}, maxWidth: {md: 450},
                        }}
                    />
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <MsgError msgError={msgError}/>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Correo electrónico"
                            name="username"
                            autoComplete="email"
                            onChange={handleChange}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Iniciar sesión
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forgot-password" variant="body2">
                                    Has olvidado la contraseña?
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <span style={{fontSize: 10, marginTop: 5, float: 'right'}}>v.{pjson.version}</span>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    </>)
}

function initialValues() {
    return {
        username: "", password: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.username) {
        error.push("El correo electrónico es obligatorio")
    }
    if (!schema.password) {
        error.push("La contraseña es obligatoria")
    }

    return error
}
