import React from "react";
import {TextField} from "@mui/material";

export default function InputFile(props) {

    const {label, name, onChange, value, required} = props

    return (
        <TextField
            type="file"
            inputProps={{accept:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}}
            variant="outlined"
            className="form-control"
            size="small"
            fullWidth
            InputLabelProps={{shrink: true}}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
        />
    )
}