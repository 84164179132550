import React, {useMemo, useRef, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import TableFilter from "../../../../components/Admin/TableFilter";
import {Button, Grid, Paper} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {
    importOrganizationsApi,
    loadImportOrganizationsApi,
    showImportOrganizationsApi
} from "../../../../services/Admin/organization";
import InputFile from "../../../../components/Inputs/InputFile";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import {SelectColumnFilter} from "../../../../components/TableComponents/SelectColumnFilter";


export default function Import() {
    const [organizations, setOrganizations] = useState([])
    const {logout} = useAuth()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const [loadingValidation, setLoadingValidation] = useState(false)
    const [file, setFile] = useState(null)
    const form = useRef()
    const optionsPerteneciente = {USUARIO_A: "Usuario A", USUARIO_B: "Usuario B", USUARIO_C: "Usuario C"}

    const columns = useMemo(
        () => [
            {
                Header: "CODIGO CLIENTE",
                accessor: "clientCode"
            },
            {
                Header: "CODIGO INTERNO",
                accessor: "internalCode"
            },
            {
                Header: "NIF",
                accessor: "nif"
            },
            {
                Header: "NIF/IVA",
                accessor: "nifiva"
            },
            {
                Header: "EPIGRAFE",
                accessor: "epigrafeName",
                Filter: SelectColumnFilter,
            },
            {
                Header: "NOMBRE",
                accessor: "name",
            },
            {
                Header: "NOMBRE CHINO",
                accessor: "nameChinese",
            },
            {
                Header: "REGIMEN",
                accessor: "regimen",
            },
            {
                Header: "TARIFA",
                accessor: "rate",
            },
            {
                Header: "ESTRADO",
                accessor: "status",
            },
            {
                Header: "PERTENECIENTE",
                accessor: "pertenecienteFiltrado",
                Filter: SelectColumnFilter,
            }
        ],
        []
    )

    const loadImportOrganizations = () => {
        showImportOrganizationsApi(logout)
            .then(data => {
                setOrganizationsData(data.data)
            })
            .catch(() => {
            });
    }

    function setOrganizationsData(snapshot) {
        const temp = snapshot.map((doc) => {
            return {
                epigrafeName: doc?.epigrafe?.name,
                pertenecienteFiltrado: optionsPerteneciente[doc.perteneciente],
                ...doc
            }
        })
        setOrganizations(temp)
    }

    const handleChangeFile = (event) => {
        setFile(event.target.files)
        state[event.target.name] = true
        setState({...state})

    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setMsgError([])
        setOrganizations(null)
        setLoading(true)
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        void importOrganizations()


    }

    const loadData = async () => {
        setLoadingValidation(true)
        loadImportOrganizationsApi(logout).then(response => {
            setMsgSuccess(response.data?.msg)
            setOrganizations(null)
            setLoadingValidation(false)

        }).catch(error => {
            setMsgError([error.response.data.errors])
            setLoadingValidation(false)

        })
    }

    const importOrganizations = async () => {
        setMsgError([])
        setMsgSuccess([])
        let f = new FormData()
        for (const property in state) {
            if (property !== "file") {
                f.append(`${property}`, state[property])
            }

        }
        if (file) {
            f.append("file", file[0])
        }
        void importOrganizationsApi(logout, f).then(response => {
            loadImportOrganizations()
            setMsgSuccess(response.data?.msg)
            setLoading(false)
        }).catch(error => {
            form.current.reset()
            setMsgError([error.response.data.errors])
            setLoading(false)
        })
    }

    const downloadTemplate = () => {
        window.open("/import_clientes.xlsx", '_blank');
    }

    return (
        <>
            <Grid item xs={6}>
                <h1>Importación Organizaciones</h1>
            </Grid>

            <Grid item xs={6} style={{textAlign: "right"}}>
                <div className="mt-2">
                    <Button
                        type="button"
                        onClick={downloadTemplate}
                        variant="contained"
                        color="primary">Descargar plantilla</Button>
                </div>
            </Grid>
            <Grid item xs={4}>
                <form ref={form}>
                    <InputFile
                        label="Importar"
                        name="file"
                        onChange={handleChangeFile}
                    />
                </form>
            </Grid>
            
            {loading ? (
                <Grid item xs={6}>
                    <LoadingButton 
                        loading
                        variant="outlined"
                     >
                        Importando Organizaciones
                    </LoadingButton>
                </Grid>
            ) : (
            <Grid item xs={6}>
                <Button
                    type="button"
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                >
                    Importar XLS Organizaciones
                </Button>
                
            </Grid>
            )}

            <MsgSuccess msgSuccess={msgSuccess}/>
            <MsgError msgError={msgError}/>

            <Grid item xs={12}>
                <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                    <TableFilter
                        columns={columns}
                        data={organizations ?? []}
                    />
                </Paper>
            </Grid>
            {loadingValidation ? (
                <Grid item xs={6}>
                    <LoadingButton 
                        loading
                        variant="outlined"
                     >
                        Validando Organizaciones

                    </LoadingButton>
                </Grid>
            ) : (
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={loadData}
                >
                    Validar Organizaciones
                </Button>
                
            </Grid>
            )}


        </>
    );
}

function initialValues() {
    return {
        file: null,
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.file) {
        error.push("El fichero es obligatorio")
    }
    return error
}
