import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersManagement from "../../../containers/Admin/Organization/Management"
import {Helmet} from "react-helmet";
import React from "react";
import CheckRole from "../../../components/Auth/checkRole";
import useAuth from "../../../hooks/useAuth";

export default function Management(props) {

    const {auth} = useAuth();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const archived = params.get('archived');

    if (!auth) {
        history.push("/login");
    }
    if (archived === '1') {
        return (
            <Basic menu="archived-organizations">
                <CheckRole roles={props.roles}/>
                <Helmet>
                    <title>Organizations Management</title>
                </Helmet>
                <ContainersManagement/>
            </Basic>
        )
    }else{
        return (
            <Basic menu="no-archived-organizations">
                <CheckRole roles={props.roles}/>
                <Helmet>
                    <title>Organizations Management</title>
                </Helmet>
                <ContainersManagement/>
            </Basic>
        )
    }
}
