import axios from 'axios';
import {removeToken} from "../services/Auth/token";

function getOptions(url, method, auth = null, body = null, file = false) {
    const options = {
        url, method, headers: {}, data: null, responseType: "json", params: {}
    };
    if (auth) {
        options.headers.Authorization = auth;
    }
    if (body) {
        if (method === "GET") {
            options.params = body;
        } else {
            options.data = body;
        }
    }
    if (file) {
        options.headers.Accept = "application/octet-stream";
        options.responseType = "arraybuffer";
    }
    return options;
}

function handlePossibleLogoOut(err, callback) {
    if (err.response) {
        if (err.response.data.code === 401) {
            removeToken()
            window.location.replace("/login")
        } else {
            return err;
        }
    } else {
        return err;
    }
}

export async function Get(url, callback, auth = null, params = null) {
    try {
        const {data} = await axios(getOptions(url, 'GET', auth, params));
        return data;
    } catch (err) {
        throw handlePossibleLogoOut(err, callback);
    }
}

export async function Post(url, callback, body, auth = null) {
    try {
        const {data} = await axios(getOptions(url, 'POST', auth, body));
        return data;
    } catch (err) {
        throw handlePossibleLogoOut(err, callback);
    }
}

export async function Put(url, callback, body, auth = null) {
    try {
        const {data} = await axios(getOptions(url, 'PUT', auth, body));
        return data;
    } catch (err) {
        throw handlePossibleLogoOut(err, callback);
    }
}

export async function Delete(url, callback, body = null, auth = null) {
    try {
        const {data} = await axios(getOptions(url, 'DELETE', auth, body));
        return data;
    } catch (err) {
        throw handlePossibleLogoOut(err, callback);
    }
}

export async function CustomRequest(url, method, callback, auth, body) {
    try {
        const {data} = await axios(getOptions(url, method, auth, body));
        return data;
    } catch (err) {
        throw handlePossibleLogoOut(err, callback);
    }
}

export async function DownloadFile(url, callback, body, auth = null) {
    try {
        const {data} = await axios(getOptions(url, 'POST', auth, body, true));
        return data;
    } catch (err) {
        throw handlePossibleLogoOut(err, callback);
    }
}
