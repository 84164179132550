import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
export default function PopupDelete(props) {
    const {handleDeleteTrue, handleDeleteFalse, show, message = "¿Estás seguro de querer eliminar este registro?"} = props

    return (
        <div>
            <Dialog
                open={show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Eliminar registro"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteFalse}>Cancelar</Button>
                    <Button onClick={handleDeleteTrue} autoFocus sx={{ color: 'error.main' }}>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}