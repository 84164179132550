import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import InputSelect2 from "../../../../components/Inputs/InputSelect2";
import {showModelsApi, showOrganizationsApi} from "../../../../services/Admin/dashboard";
import useAuth from "../../../../hooks/useAuth";

export default function Management() {

    const curYear = new Date().getFullYear()
    const [state, setState] = useState(initialValues)
    const [models, setModels] = useState([])
    const [archivedOrganizations, setArchivedOrganizations] = useState([])
    const [activeOrganizations, setActiveOrganizations] = useState([])
    const {logout} = useAuth()

    let optionsSaleYear = []

    for (let i = 2022; i <= curYear; i++) {
        optionsSaleYear.push({id: i, name: i})
    }

    let optionsSaleQuarter = [
        {id: "1T", name: "Primer trimestre"},
        {id: "2T", name: "Segundo trimestre"},
        {id: "3T", name: "Tercer trimestre"},
        {id: "4T", name: "Cuarto trimestre"},
    ]
    let optionsSaleModel = [
        {id: "303", name: "303"},
        {id: "349", name: "349"},
        {id: "390", name: "390"}
    ]

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        setModels([])
        const timeOutId = setTimeout(() => {
            if(state.year && state.model === "390"){
                showModelsApi(logout, state).then(data => {
                    setModels(data.data)
                })
            }
            if (state.year && state.quarter && state.model) {
                showModelsApi(logout, state).then(data => {
                    setModels(data.data)
                })
            }

        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            showOrganizationsApi(logout).then(data => {
                setActiveOrganizations(data.data.activeOrgs)
                setArchivedOrganizations(data.data.archivedOrgs)
            })
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [])

    return (
        <>
            <Grid item xs={6}>
                <h1>Dashboard</h1>
            </Grid>
            <Grid container spacing={1} style={{marginBottom: '20px'}}>
                <Grid item xs={3}>
                    <InputSelect2
                        label={"Modelo"}
                        name="model"
                        onChange={handleChange}
                        value={state?.model}
                        options={optionsSaleModel}>
                    </InputSelect2>
                </Grid>
                <Grid item xs={3}>
                    <InputSelect2
                        label={"Año"}
                        name="year"
                        onChange={handleChange}
                        value={state?.year}
                        options={optionsSaleYear}>
                    </InputSelect2>
                </Grid>
                <Grid item xs={3}>
                    {state.model !== "390" && (
                        <InputSelect2
                            label={"Trimestre"}
                            name="quarter"
                            onChange={handleChange}
                            value={state?.quarter}
                            options={optionsSaleQuarter}>
                        </InputSelect2>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <p>Organizaciones pendientes de presentar modelo <b>{state.model}</b>, año <b>{state.year}</b>, trimestre <b>{state.quarter}</b>: <b>{models}</b></p>
                <p>Organizaciones activas: <b>{activeOrganizations}</b></p> <p>Organizaciones archivadas: <b>{archivedOrganizations}</b></p>
            </Grid>
        </>
    )
}

function initialValues(props) {
    return {
        year: props?.year || "",
        quarter: props?.quarter || "",
        model: props?.model || "",
    }
}
