import React from "react";
import {Grid, Box} from "@mui/material";
export default function MsgSuccess(props) {

    const {msgSuccess} = props

    if (msgSuccess?.length === 0) { return "" }

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Box className="alert-success">
                {Array.isArray(msgSuccess) ? (
                    msgSuccess.map((doc, key) => {
                        return ( <span key={key}>{doc} <br /></span>)
                    })
                ) : (
                    <span>{msgSuccess}</span>
                )}
            </Box>
        </Grid>
    )
}