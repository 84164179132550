import React from "react";
import {CircularProgress, Box} from "@mui/material";
export default function LoadingProgress(props) {
    let showIn = props.show || false
    return (
        <>
            {showIn && (
                <Box sx={{maxWidth: "50px", marginLeft: "auto", marginRight: "auto", marginTop: "100px", marginBottom: "100px"}}>
                    <CircularProgress sx={{ color: '#5046e4', animationDuration: '800ms' }}/>
                </Box>
            )}
        </>
    )
}
