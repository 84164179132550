import React, {useState} from "react";
import {sendRecoveryEmail} from "../../../services/Auth/login"
import MsgError from "../../../components/MsgError";
import {Button, TextField} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import image from "../../../assets/logo.png";

const theme = createTheme();

export default function ForgottenPasswordForm() {

    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return false;
        }

        sendRecoveryEmail(state).then().catch(error => {
            setMsgError([error.response.data.errors])
        })
    }

    return (
        <>
            <ThemeProvider theme={theme}>

                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <Box
                            component="img"
                            alt="logo Sinolegal"
                            src={image}
                            sx={{
                                height: 233,
                                width: 450,
                                maxHeight: {md: 167},
                                maxWidth: {md: 450},
                            }}
                        />
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <MsgError msgError={msgError}/>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Correo electrónico"
                                name="username"
                                autoComplete="email"
                                onChange={handleChange}
                                autoFocus
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Enviar correo de recuperación
                            </Button>
                            <Grid container>
                                <Grid item>
                                    <Link href="/login" variant="body2">
                                        {"Volver"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    )
}

function initialValues() {
    return {
        username: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.username) {
        error.push("El correo electrónico es obligatorio")
    }

    return error
}