import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { createApi, getSalesApi, updateSalesApi } from "../../../../services/Admin/sale";
import InputNumber from "../../../../components/Inputs/InputNumber";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import { Button, Grid, Paper, TextField } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import InputSelect from "../../../../components/Inputs/InputSelect";
import { showOrganizationsApi } from "../../../../services/Admin/organization";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import InputComplexAutocomplete from "../../../../components/Inputs/InputComplexAutocomplete";

export default function Edit() {
    const { id } = useParams()
    const { logout } = useAuth()
    const history = useHistory()
    const [msgError, setMsgError] = useState([])
    const [state, setState] = useState(initialValues())
    const [optionsOrganization, setOptionsOrganization] = useState([])
    const [loadingOrganization, setLoadingOrganization] = useState(false)
    const [nameOrganizationFilter, setNameOrganizationFilter] = useState("")

    const curYear = new Date().getFullYear()
    let optionsSaleYear = []
    for (let i = 2022; i <= curYear; i++) {
        optionsSaleYear.push({ [i]: i })
    }
    const optionsSaleQuarter = [{ "1T": "Primer trimestre" }, { "2T": "Segundo trimestre" }, { "3T": "Tercer trimestre" }, { "4T": "Cuarto trimestre" }]
    const optionsVatType = [{ "4": "4%" }, { "10": "10%" }, { "21": "21%" }]

    useEffect(() => {
        if (id) {
            void getSale()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getSale = async () => {
        const response = await getSalesApi(logout, id)
        if (response.status) {
            setState(initialValues(response.data))
        }
    }

    const handleChangeGetOrganizations = (event) => {
        setNameOrganizationFilter(event.target.name)
        state["organization"] = event.target.value
        setState({ ...state })
    }

    useEffect(() => {
        setLoadingOrganization(false)
        setOptionsOrganization([])

        if (nameOrganizationFilter.length > 0) {
            setLoadingOrganization(true)
            const timeOutId = setTimeout(() => {
                getOrganization(nameOrganizationFilter)
            }, 1500)
            return () => clearTimeout(timeOutId)
        }

    }, [nameOrganizationFilter])

    const getOrganization = async (nif) => {
        const response = await showOrganizationsApi(logout, { id, nif })
        if (response.status) {
            let organizations = response.data
            organizations = organizations.map(organization => {
                return { id: organization.id, name: organization.nif + " | " + organization.name + " | " + organization.nameChinese }
            })
            setOptionsOrganization(organizations)
            setLoadingOrganization(false)
        } else {
            setOptionsOrganization([])
            setLoadingOrganization(false)
        }
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({ ...state })
    }
    const handleChangeNumber = (event) => {
        if (event.target.value !== "") {
            state[event.target.name] = parseFloat(event.target.value)
            setState({ ...state })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setMsgError([])
        let error = validationSchema(state)
        if (error.length !== 0) {
            setMsgError(error)
            return
        }
        if (id) {
            void updateSale()
        } else {
            void createSale()
        }
    }
    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({ ...state })
    }

    const updateSale = async () => {
        setMsgError([])
        const response = await updateSalesApi(logout, state)
        if (response.status) {
            history.push("/admin/sales/management")
        } else {
            setMsgError(response.errors)
        }
    }

    const createSale = async () => {
        createApi(state).then(resp => {
            history.push("/admin/sales/management")
        }).catch(error => {
            setMsgError(error.response.data.errors)
        })
    }

    return (
        <>
            <HeaderPage title="Configuración de la venta" />
            <MsgError msgError={msgError} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">Ajustes</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <InputComplexAutocomplete
                                    label="Organización"
                                    name="organization"
                                    options={optionsOrganization}
                                    loading={loadingOrganization}
                                    required
                                    value={state?.organization || ""}
                                    setValue={setOptionsOrganization}
                                    onChange={handleChangeGetOrganizations}
                                />
                            </Grid>

                            <Grid item xs={6} md={6} lg={6}>
                                <InputSelect
                                    label="Año"
                                    name="year"
                                    required
                                    options={optionsSaleYear}
                                    value={state?.year || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <InputSelect
                                    label="Trimestre"
                                    name="quarter"
                                    required
                                    options={optionsSaleQuarter}
                                    value={state?.quarter || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="Venta Exenta de IVA"
                                    name="exempt"
                                    required
                                    value={state?.exempt}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="Venta B2B interior"
                                    name="interiorB2b"
                                    required
                                    value={state?.interiorB2b}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="Entrega Intracomunitaria"
                                    name="intracommunity"
                                    value={state?.intracommunity}
                                    required
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="Venta Con IVA"
                                    name="vat"
                                    required
                                    value={state?.vat}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="Devolución OP Interior"
                                    name="interiorOp"
                                    required
                                    value={state?.interiorOp}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="Adquisición Interior"
                                    name="interiorAcquisition"
                                    required
                                    value={state?.interiorAcquisition}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="Adquisición Intracomunitaria"
                                    name="intracommunityAcquisition"
                                    required
                                    value={state?.intracommunityAcquisition}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="IVA Pagado en importación"
                                    name="importVat"
                                    required
                                    value={state?.importVat}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="Exportación"
                                    name="export"
                                    required
                                    value={state?.export}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="IVA Trimestre Anterior"
                                    name="previousQuarter"
                                    required
                                    value={state?.previousQuarter}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputNumber
                                    label="Venta no sujeta"
                                    name="notSubject"
                                    required
                                    value={state?.notSubject}
                                    onChange={handleChangeNumber}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputSelect
                                    label="Tipo de IVA"
                                    name="vatType"
                                    required
                                    options={optionsVatType}
                                    value={state?.vatType || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <InputSwitch
                                    label="Notificado"
                                    name="isNotified"
                                    checked={state?.isNotified}
                                    onChange={handleChangeChecked}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <Button
                        type="button"
                        href="/admin/sales/management"
                        fullWidth
                    >
                        <ArrowBackIos />
                        Volver al listado
                    </Button>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        onClick={handleSubmit}
                    >
                        {id ? "Modificar" : "Crear"}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        id: props?.id || "",
        organization: props?.organization?.id || "",
        year: props?.year || "",
        quarter: props?.quarter || "",
        exempt: ((props?.exempt !== false) ? props?.exempt : 0),
        interiorB2b: ((props?.interiorB2b !== false) ? props?.interiorB2b : 0),
        intracommunity: ((props?.intracommunity !== false) ? props?.intracommunity : 0),
        vat: ((props?.vat !== false) ? props?.vat : 0),
        interiorAcquisition: ((props?.interiorAcquisition !== false) ? props?.interiorAcquisition : 0),
        intracommunityAcquisition: ((props?.intracommunityAcquisition !== false) ? props?.intracommunityAcquisition : 0),
        importVat: ((props?.importVat !== false) ? props?.importVat : 0),
        interiorOp: ((props?.interiorOp !== false) ? props?.interiorOp : 0),
        export: ((props?.export !== false) ? props?.export : 0),
        previousQuarter: ((props?.previousQuarter !== false) ? props?.previousQuarter : 0),
        notSubject: ((props?.notSubject !== false) ? props?.notSubject : 0),
        isNotified: props?.isNotified || false,
        vatType: ((props?.vatType !== false) ? props?.vatType : "21"), // Default to "21%" if vatType is false
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.organization) {
        error.push("Es obligatorio escoger una organizacion a la que assignar la venta")
    }
    if (!schema.year) {
        error.push("Es obligatorio escoger un año al que assignar la venta")
    }
    if (!schema.quarter) {
        error.push("Es obligatorio escoger un trimestre al que assignar la venta")
    }
    if (typeof schema.exempt !== 'number') {
        error.push("La exención de IVA no puede estar vacía")
    }
    if (typeof schema.interiorB2b !== 'number') {
        error.push("La venta interior B2B no puede estar vacía")
    }
    if (typeof schema.intracommunity !== 'number') {
        error.push("La entrega intracomunitaria no puede estar vacía")
    }
    if (typeof schema.vat !== 'number') {
        error.push("La venta con IVA no puede estar vacía")
    }
    if (typeof schema.interiorAcquisition !== 'number') {
        error.push("La adquisición interior no puede estar vacía")
    }
    if (typeof schema.intracommunityAcquisition !== 'number') {
        error.push("La adquisición intracomunitaria no puede estar vacía")
    }
    if (typeof schema.importVat !== 'number') {
        error.push("El IVA pagado en importación no puede estar vacío")
    }
    if (typeof schema.interiorOp !== 'number') {
        error.push("La devolución OP interior no puede estar vacía")
    }
    if (typeof schema.export !== 'number') {
        error.push("La exportación no puede estar vacía")
    }
    if (typeof schema.previousQuarter !== 'number') {
        error.push("El IVA A devolver del trimestre anterior no puede estar vacío")
    }
    if (typeof schema.notSubject !== 'number') {
        error.push("La venta no sujeta no puede estar vacía")
    }
    if (!schema.vatType) {
        error.push("El tipo de IVA no puede estar vacío")
    }

    return error
}
