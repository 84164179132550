import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {Get} from "../../utils/request";
import {getToken} from "./token";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}`;
const token = `Bearer ${getToken()}`;

export async function impersonateApi(data, logout) {

    const url = `${baseUrl}/user/impersonate/${data.id}`;
    const ret = await Get(url, logout, token);
    return ret;
}