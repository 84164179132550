import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import { Get, Post, Put } from "../../utils/request";
import {getToken} from "../Auth/token";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}`;
const token = `Bearer ${getToken()}`;

export async function showUserRolesApi(logout) {
    const url = `${baseUrl}/user-role/list`;
    const ret = await Get(url, logout, token);
    return ret;
}

