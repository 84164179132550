import React from "react";
import {FormControl, MenuItem, Select} from "@mui/material";

export function SelectColumnFilter({column: {filterValue, setFilter, preFilteredRows, id},}) {

    const options = React.useMemo(() => {
        const options = [];
        preFilteredRows.forEach((row) => {
            if (row.values[id] && options.indexOf(row.values[id]) === -1) {
                options.push(row.values[id]);
            }
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    return (
        <FormControl
            variant="outlined"
            fullWidth>
            <Select
                value={filterValue}
                size="small"
                onChange={(e) => {
                    setFilter(e.target.value);
                }}>
                <MenuItem value="">
                    <em>All</em>
                </MenuItem>
                {options.map((option, i) => (
                    <MenuItem key={i} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}