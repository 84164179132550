import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {createApi, getUserApi, updateUserApi} from "../../../../services/Admin/user";
import InputText from "../../../../components/Inputs/InputText";
import InputPassword from "../../../../components/Inputs/InputPassword";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {impersonateApi} from "../../../../services/Auth/impersonate";
import {Button, Grid, Paper} from "@mui/material";
import {ArrowBackIos} from "@mui/icons-material";
import {showUserRolesApi} from "../../../../services/Admin/userRole";

export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {logout, login} = useAuth()

    const [msgError, setMsgError] = useState([])
    const [state, setState] = useState(initialValues)

    const [optionsRole, setOptionsRole] = useState([])

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        void getUserRoles()
        if (id) {
            void getUser()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUser = async () => {
        const response = await getUserApi(logout, id)
        if (response.status) {
            setState(initialValues(response.data))
        }
    }

    const getUserRoles = async () => {
        const response = await showUserRolesApi(logout, id)
        if (response.status) {
            let roles = response.data
            roles = roles.map(role => {
                let obj = {};
                obj[role.key] = role.name
                return obj
            })
            setOptionsRole(roles)
        }
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state, id)

        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        if (id) {
            void updateUser()
        } else {
            void createUser()
        }
    }

    const updateUser = async () => {
        setMsgError([])

        const response = await updateUserApi(logout, state)
        if (response.status) {
            history.push("/admin/users/management")
        } else {
            setMsgError(response.errors)
        }
    }

    const createUser = async () => {
        setMsgError([])
        const response = await createApi(state)
        if (response.status) {
            history.push("/admin/users/management")
        } else {
            setMsgError(response.errors)
        }
    }

    const clickImpersonate = async () => {
        setMsgError([])

        const response = await impersonateApi({id: id}, logout)
        if (response.status) {
            login(response.token)
            history.push("/dash/my-account")
        } else {
            setMsgError(response.errors)
        }
    }

    return (
        <>
            <HeaderPage title="Configuraciones de la cuenta"/>

            <MsgError msgError={msgError}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">Ajustes</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <div className="mb-2">
                                    <InputText
                                        label="Nombre"
                                        name="name"
                                        value={state?.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="mb-2">
                                    <InputText
                                        label="Apellidos"
                                        name="surnames"
                                        value={state?.surnames}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="mb-2">
                                    <InputText
                                        label="Correo electrónico"
                                        name="email"
                                        value={state?.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">Seguridad</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <div className="mb-2">
                                    <InputSelect
                                        label="Tipo de usuario (Rol)"
                                        name="role"
                                        onChange={handleChange}
                                        value={state?.role}
                                        options={optionsRole}
                                        required
                                    />
                                </div>

                                <div className="mb-2">
                                    <InputPassword
                                        label="Contraseña"
                                        name="password"
                                        value={state?.password}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="mb-2">
                                    <InputSwitch
                                        label="Acceso a la plataforma"
                                        name="isActive"
                                        checked={state?.isActive}
                                        onChange={handleChangeChecked}
                                    />
                                </div>

                                {id && (
                                    <div className="mb-2">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            fullWidth
                                            onClick={clickImpersonate}
                                        >
                                            Impersonar
                                        </Button>
                                    </div>
                                )}

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <Button
                        type="button"
                        href="/admin/users/management"
                        fullWidth
                    >
                        <ArrowBackIos/>
                        Volver al listado
                    </Button>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        onClick={handleSubmit}
                    >
                        {id ? "Modificar" : "Crear"}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {

    return {
        id: props?.id || "",
        name: props?.name || "",
        surnames: props?.surnames || "",
        email: props?.email || "",
        password: "",
        isActive: props?.isActive,
        role: props?.role.key || "",
    }
}

function validationSchema(schema, id) {

    let error = []

    if (!schema.name) {
        error.push("El nombre es obligatorio")
    }
    if (!schema.email) {
        error.push("El email es obligatorio")
    }
    if (!schema.role) {
        error.push("El rol es obligatorio")
    }

    if (!id) {
        if (!schema.password) {
            error.push("La contraseña es obligatoria")
        }
    }

    return error
}
