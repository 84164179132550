import React, {useState} from "react";
import {recoverPassword} from "../../../services/Auth/login"
import MsgError from "../../../components/MsgError";
import {useHistory, useParams} from "react-router-dom";
import {Button, TextField} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import image from "../../../assets/logo.png";

const theme = createTheme();

export default function RecoverPasswordForm() {

    const {token} = useParams();
    const history = useHistory()
    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return false;
        }

        const response = await recoverPassword(state, token)

        if (response?.status) {
            history.push("/login")
        }
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <MsgError msgError={msgError}/>
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <Box
                            component="img"
                            alt="logo Sinolegal"
                            src={image}
                            sx={{
                                height: 233,
                                width: 450,
                                maxHeight: {md: 167},
                                maxWidth: {md: 450},
                            }}
                        />
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="Contraseña"
                                name="password"
                                type="password"
                                autoComplete="email"
                                onChange={handleChange}
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="repPassword"
                                label="Repetir contraseña"
                                type="password"
                                id="repPassword"
                                autoComplete="current-password"
                                onChange={handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Cambiar contraseña
                            </Button>
                            <Grid container>
                                <Grid item>
                                    <Link href="/login" variant="body2">
                                        {"Volver"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    )
}

function initialValues() {
    return {
        password: "",
        repPassword: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.password) {
        error.push("La contraseña es obligatoria")
    }

    if (!schema.repPassword) {
        error.push("La contraseña es obligatoria")
    }

    return error
}