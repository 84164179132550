import React from "react";
import {TextField} from "@mui/material";

export default function InputPassword(props) {

    const {label, name, value, onChange, required} = props

    return (
        <TextField
            type="password"
            variant="outlined"
            className="form-control"
            size="small"
            fullWidth
            inputProps={{
                autoComplete: "autocomplete=\"ÑÖcompletes\"",
                form: {
                    autoComplete: "off",
                },
            }}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
        />
    )
}