import React from "react";
import {TextField} from "@mui/material";

export default function InputText(props) {

    const {label, name, value, onChange, required} = props

    return (
        <TextField
            type="text"
            variant="outlined"
            className="form-control"
            size="small"
            fullWidth
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            InputLabelProps={{ shrink: true }}
        />
    )
}
